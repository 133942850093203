<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      item-key="index"
      class="quotation-table"
      :item-class="rowClass"
      mobile-breakpoint="768"
    >
      <template v-slot:item.category="{ item }">
        <span :class="item.categoryClass">{{ item.category }}</span>
      </template>
      <template v-slot:item.product="{ item }">
        <div class="col-product">
          <v-textarea
            v-model="item.name"
            label="請輸入商品名稱、型號、規格等資訊"
            auto-grow
            hide-details
            rows="1"
            row-height="15"
            solo
            dense
            append-outer-icon="mdi-plus-circle"
            @click:append-outer="addRow(item)"
            :class="item.addRowCLass"
            @keyup.native="store"
          ></v-textarea>
        </div>
      </template>
      <template v-slot:item.count="{ item }">
        <div class="col-count">
          <v-text-field
            v-model.number="item.count"
            append-outer-icon="mdi-plus"
            prepend-icon="mdi-minus"
            hide-details
            solo
            dense
            @click:append-outer="changeCount(item, 1)"
            @click:prepend="changeCount(item, -1)"
          ></v-text-field>
        </div>
      </template>
      <template v-slot:item.comment="{ item }">
        <div class="col-comment">
          <v-textarea
            v-model="item.comment"
            label="請輸入網址、需求等參考資訊"
            auto-grow
            hide-details
            rows="1"
            row-height="15"
            solo
            dense
            append-outer-icon="mdi-close"
            @click:append-outer="removeRow(item)"
            @keyup.native="store"
          ></v-textarea>
        </div>
      </template>
    </v-data-table>
    <div class="tw-text-center">
      <a
        @click="submit"
        class="
          tw-mt-12
          v-btn v-btn--is-elevated v-btn--has-bg
          theme--light
          elevation-2
          v-size--default
          tw-bg-primary tw-text-white
        "
        >下一步</a
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "項目",
          sortable: false,
          value: "category",
        },
        {
          text: "商品",
          sortable: false,
          value: "product",
        },
        {
          text: "數量",
          sortable: false,
          value: "count",
          width: 0,
        },
        {
          text: "備註",
          sortable: false,
          value: "comment",
        },
      ],
      items: [
        {
          category: "其他",
          count: 0,
          name: null,
        },
      ],
    };
  },
  created() {
    this.calcClass();
    var storeData = localStorage.getItem("wish");
    if (storeData != null) {
      this.items = JSON.parse(storeData);
    }
  },
  methods: {
    changeCount(item, num) {
      item.count += num;
      if (item.count < 1) item.count = 1;
      item.total = item.price != null ? item.price * item.count : null;
      this.store();
    },
    addRow(item) {
      var index = this.items.indexOf(item);
      this.items.splice(index + 1, 0, {
        category: "其他",
        count: 1,
        name: null,
      });
      this.calcClass();
      this.store();
    },
    removeRow(item) {
      var el = this.items.filter((el) => el.category == item.category);
      if (el.length > 1) {
        var index = this.items.indexOf(item);
        this.items.splice(index, 1);
        this.calcClass();
      } else {
        item.name = null;
        item.comment = null;
        item.count = 1;
      }
      this.store();
    },
    calcClass() {
      var category = null;
      for (var i = 0; i < this.items.length; i++) {
        this.items[i].index = i + 1;
        this.items[i].addRowCLass = "hide-icon";
        if (i == this.items.length - 1) {
          this.items[i].addRowCLass = "";
        }
        if (category == this.items[i].category) {
          this.items[i].categoryClass = "hide-category";
        } else {
          this.items[i].categoryClass = "";
        }

        category = this.items[i].category;
      }
    },
    rowClass(item) {
      if (item.categoryClass == "hide-category") {
        return "row-hide-category";
      }
    },
    store() {
      localStorage.setItem("wish", JSON.stringify(this.items));
    },
    submit() {
      const wish = JSON.parse(localStorage.getItem("wish"));
      if (this.type === "cart") {
        if (document.querySelector(".money").textContent === "$0") {
          alert("請選擇商品");
        } else {
          window.location.href = "/checkout?type=quote";
        }
      }
      
      if (this.type === "wish") {
        if (wish === null || !wish[0].count || !wish[0].name) {
          alert("請輸入許願商品或選擇數量");
        } else {
          window.location.href = "/checkout?type=wish";
        }
      }
    },
  },
};
</script>
