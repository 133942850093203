import VueAxios from 'vue-axios';
import Vuetify from 'vuetify';
import axios from 'axios';

require('./bootstrap');

window.Vue = require('vue').default;
Vue.component(
  'HelloWorld',
  require('./components/HelloWorld.vue').default,
);

Vue.component('homepage-announcement', require('./components/homepage/Announcement.vue').default);
Vue.component('homepage-quote', require('./components/homepage/Quote.vue').default);
Vue.component('homepage-wish', require('./components/homepage/Wish.vue').default);
Vue.component('homepage-cart', require('./components/homepage/Cart.vue').default);
Vue.component('homepage-checkout-form', require('./components/homepage/CheckoutForm.vue').default);
Vue.component('homepage-order', require('./components/homepage/Order.vue').default);

Vue.use(Vuetify);
// eslint-disable-next-line no-unused-vars
const app = new Vue({
  el: '#app',
  vuetify: new Vuetify(),
  VueAxios,
  axios,
});
