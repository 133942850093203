<template>
    <div id="announcement-table">
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
        >
            <template v-slot:item.title="{ item }">
                <a :href="`/announcement/${item.id}`" style="color: #1f2937">{{ item.title }}</a>
            </template>
        </v-data-table>
        <div v-if="showPagination" class="text-center mt-12">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        items: Array,
        showPagination: {
            default: true,
        },
        itemsPerPage: {
            default: 10,
        },
    },
    data() {
        return {
            page: 1,
            pageCount: 0,
            headers: [
                {
                    text: "日期",
                    align: "center",
                    class: "table-text",
                    sortable: false,
                    cellClass: "col-date ",
                    value: "released_at",
                },
                {
                    text: "類別",
                    align: "center",
                    class: "table-text",
                    sortable: false,
                    cellClass: "col-type",
                    value: "type",
                },
                {
                    text: "標題",
                    class: "table-text",
                    sortable: false,
                    cellClass: "col-title",
                    value: "title",
                },
            ],
        };
    },
};
</script>
