<template>
  <div>
    <v-expansion-panels accordion v-for="order in orders" :key="order.id">
      <div class="order-card">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="tw-flex tw-justify-between tw-content-center header-text">
              <span>
                <span class="customer">{{ order.name }}</span>
                <span class="order-number"
                  >訂單編號：{{ order.order_number }}</span
                >
              </span>
              <span :class="`state ${order.state}`">{{ order.state }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            訂單日期：{{ order.created_at }}<br />
            統編：{{ order.tax_id }}<br />
            地址：{{ order.address }}<br />
            備註：{{ order.content }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <div class="tw-w-full tw-p-4">
          <homepage-cart
            :use-props="true"
            :items="items(order.products)"
            :wishes="wishes(order.products)"
            :total-price="totalPrice(order.products)"
          ></homepage-cart>
        </div>
      </div>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  props: {
    orders: Array,
  },
  data() {
    return {};
  },
  methods: {
    items(productCartData) {
      const storeData = JSON.parse(productCartData);
      if (storeData.cart !== null) {
        return JSON.parse(storeData.cart).filter((data) => data.id);
      }
      return [];
    },
    wishes(productCartData) {
      const storeData = JSON.parse(productCartData);
      if (storeData.wish !== null) {
        return JSON.parse(storeData.wish).filter((data) => data.name);
      }
      return [];
    },
    totalPrice(productCartData) {
      const storeData = JSON.parse(productCartData);
      let total = 0;
      if (storeData.cart !== null) {
        const storeCartData = JSON.parse(storeData.cart);
        storeCartData.forEach((product) => {
          total += product.price * product.count;
        });
      }
      return total;
    },
  },
};
</script>
