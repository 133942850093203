<template>
  <div>
    <v-data-table
      v-if="itemsValue.length"
      :headers="itemHeaders"
      :items="itemsValue"
      hide-default-footer
      item-key="index"
      class="checkout-table"
      :item-class="rowClass"
      mobile-breakpoint="768"
    >
      <template #[`item.category`]="{ item }">
        <div class="col-category">
          <span :class="item.categoryClass">{{ item.category }}</span>
        </div>
      </template>
      <template #[`item.product`]="{ item }">
        <div class="col-product">
          <span>{{ item.name }}</span>
        </div>
      </template>
      <template #[`item.count`]="{ item }">
        <div class="col-count">
          <span>{{ item.count }}</span>
        </div>
      </template>
      <template #[`item.price`]="{ item }">
        <div class="col-price">
          <span class="text-original-price">{{
            moneyFormat(item.originalPrice)
          }}</span>
          <span class="text-price">{{ moneyFormat(item.price) }}</span>
        </div>
      </template>
      <template #[`item.total`]="{ item }">
        <div class="col-total">
          <span>{{ moneyFormat(item.total) }}</span>
        </div>
      </template>
    </v-data-table>
    <v-data-table
      v-if="wishesValue.length"
      :headers="wishHeaders"
      :items="wishesValue"
      hide-default-footer
      item-key="index"
      class="checkout-table"
      :item-class="rowClass"
      mobile-breakpoint="768"
    >
      <template #[`item.category`]="{ item }">
        <div class="col-category">
          <span :class="item.categoryClass">{{ item.category }}</span>
        </div>
      </template>
      <template #[`item.product`]="{ item }">
        <div class="col-product">
          <span>{{ item.name }}</span>
        </div>
      </template>
      <template #[`item.count`]="{ item }">
        <div class="col-count">
          <span>{{ item.count }}</span>
        </div>
      </template>
      <template #[`item.comment`]="{ item }">
        <div class="col-comment">
          <span>{{ item.comment }}</span>
        </div>
      </template>
    </v-data-table>
    <div
      class="tw-px-4 tw-pb-4 sm:tw-px-0 sm:tw-pb-0"
      v-if="this.cartType !== 'wish' && totalPriceValue !== 0"
    >
      <div class="tw-flex tw-justify-end tw-gap-2 tw-mt-6">
        <div class="tw-flex tw-flex-col tw-items-end tw-gap-2">
          <div class="tw-h-6">商品小計</div>
          <div class="tw-h-6">運費</div>
          <div v-if="totalPriceValue > 3000" class="tw-h-6">$3,000 免運門檻折扣</div>
          <div class="tw-h-8 tw-leading-8">金額總計</div>
        </div>
        <div class="tw-flex tw-flex-col tw-items-end tw-gap-2 tw-w-32">
          <div class="tw-h-6 tw-text-base tw-font-medium tw-text-gray-400">
            {{ moneyFormat(totalPriceValue) }}
          </div>
          <div class="tw-h-6 tw-text-base tw-font-medium tw-text-gray-400">
            ＄100
          </div>
          <div
            v-if="totalPriceValue > 3000"
            class="tw-h-6 tw-text-base tw-font-medium tw-text-gray-400"
          >
            -＄100
          </div>
          <div class="tw-h-8 tw-text-2xl tw-font-medium" style="color: #bd2828">
            {{
              totalPriceValue > 3000
                ? moneyFormat(totalPriceValue)
                : moneyFormat(totalPriceValue + 100)
            }}
          </div>
        </div>
      </div>
      <div
        class="tw-flex tw-justify-end tw-mt-2 tw-text-xs tw-font-regular tw-text-gray-400"
      >
        此金額為估算總計，實際金額請以最後報價結果為主
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    useProps: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => [],
      require: false
    },
    wishes: {
      type: Array,
      default: () => [],
      require: false
    },
    totalPrice: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      itemHeaders: [
        {
          text: "項目",
          sortable: false,
          value: "category",
          width: "140px",
        },
        {
          text: "商品",
          sortable: false,
          value: "product",
          width: "100%",
        },
        {
          text: "數量",
          sortable: false,
          value: "count",
          width: "140px",
        },
        {
          text: "單價",
          sortable: false,
          value: "price",
          width: "140px",
        },
        {
          text: "小計",
          sortable: false,
          value: "total",
          width: "100px",
        },
      ],
      wishHeaders: [
        {
          text: "項目",
          sortable: false,
          value: "category",
          width: "80px",
        },
        {
          text: "商品",
          sortable: false,
          value: "product",
          width: "50%",
        },
        {
          text: "數量",
          sortable: false,
          value: "count",
          width: "80px",
        },
        {
          text: "備註",
          sortable: false,
          value: "comment",
          width: "50%",
        },
      ],
      cartType: null,
      itemsValue: this.items,
      wishesValue: this.wishes,
      totalPriceValue: this.totalPrice
    };
  },
  created() {
    if (!this.useProps) {
      this.cartType = new URLSearchParams(window.location.search).get("type");
      const storeDataCart = localStorage.getItem("cart");
      if (this.cartType != "wish" && storeDataCart != null) {
        this.itemsValue = JSON.parse(storeDataCart).filter((data) => data.id);
      }
      const storeDataWish = localStorage.getItem("wish");
      if (storeDataWish != null) {
        this.wishesValue = JSON.parse(storeDataWish).filter((data) => data.name);
      }
      const storeDataTotalPrice = localStorage.getItem("totalPrice");
      if (storeDataTotalPrice != null) {
        this.totalPriceValue = Number(storeDataTotalPrice);
      }
    }
  },
  methods: {
    moneyFormat(money) {
      if (money == null) return null;
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });

      return formatter.format(money);
    },
    rowClass(item) {
      if (item.categoryClass === "hide-category") {
        return "row-hide-category";
      }
      return null;
    },
  },
};
</script>
