<template>
    <div class="quotation-table">
        <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            item-key="index"
            
            :item-class="rowClass"
            mobile-breakpoint="768"
        >
            <template v-slot:item.category="{ item }">
                <div class="col-category">
                    <span :class="item.categoryClass">{{ item.category }}</span>
                </div>
            </template>
            <template v-slot:item.product="{ item }">
                <div class="col-product">
                    <v-select
                        v-model="item.id"
                        :items="getProducts(item.category)"
                        append-outer-icon="mdi-plus-circle"
                        hide-details="auto"
                        label="請選擇商品"
                        solo
                        dense
                        @change="changeProduct(item)"
                        @click:append-outer="addRow(item)"
                        :class="item.addRowCLass"
                    >
                    </v-select>
                </div>
            </template>
            <template v-slot:item.count="{ item }">
                <div class="col-count">
                    <v-text-field
                        v-model.number="item.count"
                        v-if="item.id != null"
                        append-outer-icon="mdi-plus"
                        prepend-icon="mdi-minus"
                        hide-details
                        solo
                        dense
                        @click:append-outer="changeCount(item, 1)"
                        @click:prepend="changeCount(item, -1)"
                    ></v-text-field>
                </div>
            </template>
            <template v-slot:item.price="{ item }">
                <div class="col-price">
                    <span class="text-original-price">{{
                        moneyFormat(item.originalPrice)
                    }}</span>
                    <span class="text-price">{{
                        moneyFormat(item.price)
                    }}</span>
                </div>
            </template>
            <template v-slot:item.total="{ item }">
                <div class="col-total">
                    <span>{{ moneyFormat(item.total) }}</span>
                    <v-btn
                        icon
                        @click="removeRow(item)"
                        :class="item.removeRowClass"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <div class="total-price">
            <span>商品小計</span>
            <span class="money">{{ moneyFormat(totalPrice) }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        products: Array,
    },
    data() {
        return {
            totalPrice: 0,
            headers: [
                {
                    text: "項目",
                    sortable: false,
                    value: "category",
                },
                {
                    text: "商品",
                    sortable: false,
                    value: "product",
                },
                {
                    text: "數量",
                    sortable: false,
                    value: "count",
                    width: 0,
                },
                {
                    text: "單價",
                    sortable: false,
                    value: "price",
                    width: 0,
                },
                {
                    text: "小計",
                    sortable: false,
                    value: "total",
                    width: 0,
                },
            ],
            items: [
                {
                    category: "CPU",
                    count: 1,
                    id: null,
                },
                {
                    category: "主機板",
                    count: 1,
                    id: null,
                },
                {
                    category: "RAM 記憶體",
                    count: 1,
                    id: null,
                },
                {
                    category: "儲存裝置",
                    count: 1,
                    id: null,
                },
                {
                    category: "顯示卡",
                    count: 1,
                    id: null,
                },
                {
                    category: "電源供應器",
                    count: 1,
                    id: null,
                },
                {
                    category: "電腦機殼",
                    count: 1,
                    id: null,
                },
                {
                    category: "軟體",
                    count: 1,
                    id: null,
                },
                {
                    category: "桌上型電腦",
                    count: 1,
                    id: null,
                },
            ],
        };
    },
    created() {
        this.calcClass();
        var initItems = this.items;
        var storeData = localStorage.getItem("cart");
        if (storeData != null) {
            storeData = JSON.parse(storeData);
            initItems.forEach((item) => {
                var find = storeData.filter((i) => i.category == item.category);
                if (find.length == 0) {
                    storeData.push({
                        category: item.category,
                        count: 1,
                        id: null,
                    });
                }
            });

            this.items = storeData;
        }
        this.store();
    },
    methods: {
        getProducts(category) {
            var result = [];
            var subcategories = [
                ...new Set(
                    this.products
                        .filter((el) => el.category == category)
                        .map((el) => ({
                            subcategoryId: el.subcategoryId,
                            subcategory: el.subcategory,
                        }))
                        .reduce((unique, o) => {
                            if (
                                !unique.some(
                                (obj) =>
                                    obj.subcategoryId === o.subcategoryId &&
                                    obj.subcategory === o.subcategory
                                )
                            ) {
                                unique.push(o);
                            }
                            return unique;
                        }, [])
                ),
            ];

            for (const subcategory of subcategories) {
                result.push({
                    header: subcategory.subcategory,
                });
                var products = this.products.filter(
                    (el) => el.subcategoryId == subcategory.subcategoryId
                );
                for (const product of products) {
                    result.push({
                        text: product.name,
                        value: product.id,
                    });
                }
            }

            return result;
        },
        changeProduct(item) {
            var el = this.products.filter((el) => el.id == item.id);
            item.price = el.length > 0 ? Number(el[0].price) : null;
            item.originalPrice = el.length > 0 ? Number(el[0].originalPrice) : null;
            item.subcategory = el.length > 0 ? el[0].subcategory : null;
            item.name = el.length > 0 ? el[0].name : null;
            item.total = item.price != null ? item.price * item.count : null;
            this.calcClass();
            this.store();
        },
        changeCount(item, num) {
            item.count += num;
            if (item.count < 1) item.count = 1;
            item.total = item.price != null ? item.price * item.count : null;
            this.store();
        },
        addRow(item) {
            var index = this.items.indexOf(item);
            this.items.splice(index + 1, 0, {
                category: item.category,
                count: 1,
            });
            this.calcClass();
            this.store();
        },
        removeRow(item) {
            var el = this.items.filter((el) => el.category == item.category);
            if (el.length > 1) {
                var index = this.items.indexOf(item);
                this.items.splice(index, 1);
                this.calcClass();
            } else {
                item.id = null;
                this.changeProduct(item);
            }
            this.store();
        },
        calcClass() {
            var category = null;
            for (var i = this.items.length - 1; i >= 0; i--) {
                if (
                    category == this.items[i].category ||
                    this.items[i].id == null
                ) {
                    this.items[i].addRowCLass = "hide-icon";
                } else {
                    this.items[i].addRowCLass = "";
                }
                category = this.items[i].category;
            }

            category = null;
            for (var i = 0; i < this.items.length; i++) {
                this.items[i].index = i + 1;
                if (category == this.items[i].category) {
                    this.items[i].categoryClass = "hide-category";
                } else {
                    this.items[i].categoryClass = "";
                }
                var el = this.items.filter(
                    (el) => el.category == this.items[i].category
                );
                if (el.length > 1 || this.items[i].id != null) {
                    this.items[i].removeRowClass = "";
                } else {
                    this.items[i].removeRowClass = "d-none";
                }

                category = this.items[i].category;
            }
        },
        moneyFormat(money) {
            if (money == null) return null;
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
            });
            return formatter.format(money);
        },
        rowClass(item) {
            if (item.categoryClass == "hide-category") {
                return "row-hide-category";
            }
        },
        store() {
            var sum = this.items.map(i => i.total).filter(i => i != undefined);
            this.totalPrice = sum.length > 0 ? sum.reduce((a,b)=>a+b) : 0;
            localStorage.setItem("totalPrice", this.totalPrice);
            localStorage.setItem("cart", JSON.stringify(this.items));
        },
    },
};
</script>
