<template>
  <h1>{{ title }}</h1>
</template>
<script>
export default {
  data: () => ({
    title: 'Hello World!',
  }),
};
</script>
