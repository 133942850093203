var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.itemsValue.length)?_c('v-data-table',{staticClass:"checkout-table",attrs:{"headers":_vm.itemHeaders,"items":_vm.itemsValue,"hide-default-footer":"","item-key":"index","item-class":_vm.rowClass,"mobile-breakpoint":"768"},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-category"},[_c('span',{class:item.categoryClass},[_vm._v(_vm._s(item.category))])])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-product"},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-count"},[_c('span',[_vm._v(_vm._s(item.count))])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-price"},[_c('span',{staticClass:"text-original-price"},[_vm._v(_vm._s(_vm.moneyFormat(item.originalPrice)))]),_vm._v(" "),_c('span',{staticClass:"text-price"},[_vm._v(_vm._s(_vm.moneyFormat(item.price)))])])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-total"},[_c('span',[_vm._v(_vm._s(_vm.moneyFormat(item.total)))])])]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.wishesValue.length)?_c('v-data-table',{staticClass:"checkout-table",attrs:{"headers":_vm.wishHeaders,"items":_vm.wishesValue,"hide-default-footer":"","item-key":"index","item-class":_vm.rowClass,"mobile-breakpoint":"768"},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-category"},[_c('span',{class:item.categoryClass},[_vm._v(_vm._s(item.category))])])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-product"},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-count"},[_c('span',[_vm._v(_vm._s(item.count))])])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-comment"},[_c('span',[_vm._v(_vm._s(item.comment))])])]}}],null,true)}):_vm._e(),_vm._v(" "),(this.cartType !== 'wish' && _vm.totalPriceValue !== 0)?_c('div',{staticClass:"tw-px-4 tw-pb-4 sm:tw-px-0 sm:tw-pb-0"},[_c('div',{staticClass:"tw-flex tw-justify-end tw-gap-2 tw-mt-6"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-end tw-gap-2"},[_c('div',{staticClass:"tw-h-6"},[_vm._v("商品小計")]),_vm._v(" "),_c('div',{staticClass:"tw-h-6"},[_vm._v("運費")]),_vm._v(" "),(_vm.totalPriceValue > 3000)?_c('div',{staticClass:"tw-h-6"},[_vm._v("$3,000 免運門檻折扣")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-h-8 tw-leading-8"},[_vm._v("金額總計")])]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-end tw-gap-2 tw-w-32"},[_c('div',{staticClass:"tw-h-6 tw-text-base tw-font-medium tw-text-gray-400"},[_vm._v("\n          "+_vm._s(_vm.moneyFormat(_vm.totalPriceValue))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"tw-h-6 tw-text-base tw-font-medium tw-text-gray-400"},[_vm._v("\n          ＄100\n        ")]),_vm._v(" "),(_vm.totalPriceValue > 3000)?_c('div',{staticClass:"tw-h-6 tw-text-base tw-font-medium tw-text-gray-400"},[_vm._v("\n          -＄100\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-h-8 tw-text-2xl tw-font-medium",staticStyle:{"color":"#bd2828"}},[_vm._v("\n          "+_vm._s(_vm.totalPriceValue > 3000
              ? _vm.moneyFormat(_vm.totalPriceValue)
              : _vm.moneyFormat(_vm.totalPriceValue + 100))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-end tw-mt-2 tw-text-xs tw-font-regular tw-text-gray-400"},[_vm._v("\n      此金額為估算總計，實際金額請以最後報價結果為主\n    ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }