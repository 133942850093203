var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"quotation-table",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","item-key":"index","item-class":_vm.rowClass,"mobile-breakpoint":"768"},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.categoryClass},[_vm._v(_vm._s(item.category))])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-product"},[_c('v-textarea',{class:item.addRowCLass,attrs:{"label":"請輸入商品名稱、型號、規格等資訊","auto-grow":"","hide-details":"","rows":"1","row-height":"15","solo":"","dense":"","append-outer-icon":"mdi-plus-circle"},on:{"click:append-outer":function($event){return _vm.addRow(item)}},nativeOn:{"keyup":function($event){return _vm.store.apply(null, arguments)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-count"},[_c('v-text-field',{attrs:{"append-outer-icon":"mdi-plus","prepend-icon":"mdi-minus","hide-details":"","solo":"","dense":""},on:{"click:append-outer":function($event){return _vm.changeCount(item, 1)},"click:prepend":function($event){return _vm.changeCount(item, -1)}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", _vm._n($$v))},expression:"item.count"}})],1)]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-comment"},[_c('v-textarea',{attrs:{"label":"請輸入網址、需求等參考資訊","auto-grow":"","hide-details":"","rows":"1","row-height":"15","solo":"","dense":"","append-outer-icon":"mdi-close"},on:{"click:append-outer":function($event){return _vm.removeRow(item)}},nativeOn:{"keyup":function($event){return _vm.store.apply(null, arguments)}},model:{value:(item.comment),callback:function ($$v) {_vm.$set(item, "comment", $$v)},expression:"item.comment"}})],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"tw-text-center"},[_c('a',{staticClass:"\n        tw-mt-12\n        v-btn v-btn--is-elevated v-btn--has-bg\n        theme--light\n        elevation-2\n        v-size--default\n        tw-bg-primary tw-text-white\n      ",on:{"click":_vm.submit}},[_vm._v("下一步")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }