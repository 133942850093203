<template>
  <form method="POST" :action="storeUrl">
    <input type="hidden" name="_token" :value="csrfToken" />
    <input
      type="hidden"
      name="checkoutType"
      :value="cartType === 'wish' ? '許願單' : '報價單'"
    />
    <div
      class="checkout-form tw-p-4 tw-mb-6 sm:tw-mb-12"
      style="border: 1px solid #e5e7eb"
    >
      <h1
        class="
          tw-text-left tw-text-2xl
          sm:tw-text-4xl
          tw-font-medium tw-text-gray-500 tw-mb-4
          sm:tw-mb-4 sm:tw-px-0
        "
      >
        聯絡資訊
      </h1>
      <div class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-6">
        <div class="tw-flex tw-flex-col tw-gap-4 sm:tw-w-6/12 tw-grow">
          <div class="tw-flex tw-flex-col tw-gap-2">
            <label>
              <span class="tw-font-regular tw-text-base tw-text-gray-800"
                >姓名</span
              >
              <span class="tw-font-medium tw-text-base tw-text-secondary"
                >*</span
              >
            </label>
            <input
              id="name"
              type="text"
              name="name"
              placeholder="請輸入姓名"
              required
            />
          </div>
          <div class="tw-flex tw-flex-col tw-gap-2">
            <label>
              <span class="tw-font-regular tw-text-base tw-text-gray-800"
                >電話</span
              >
              <span class="tw-font-medium tw-text-base tw-text-secondary"
                >*</span
              >
            </label>
            <input
              id="phone"
              type="text"
              name="phone"
              placeholder="請輸入電話"
              pattern=".{7,10}[0-9]"
              required
            />
          </div>
          <div class="tw-flex tw-flex-col tw-gap-2">
            <label>
              <span class="tw-font-regular tw-text-base tw-text-gray-800"
                >E-mail</span
              >
              <span class="tw-font-medium tw-text-base tw-text-secondary"
                >*</span
              >
            </label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder="請輸入 E-mail"
              required
            />
          </div>
          <div class="tw-flex tw-flex-col tw-gap-2">
            <label>
              <span class="tw-font-regular tw-text-base tw-text-gray-800"
                >地址</span
              >
              <span class="tw-font-medium tw-text-base tw-text-secondary"
                >*</span
              >
            </label>
            <div class="tw-flex tw-flex-col tw-gap-2">
              <div class="tw-flex tw-gap-3">
                <select
                  v-model="cityIdx"
                  class="tw-grow"
                  name="city"
                  @change="initArea"
                  required
                >
                  <option value="" disabled>請選擇縣市</option>
                  <option v-for="(city, idx) in cities" :key="idx" :value="idx">
                    {{ city.name }}
                  </option>
                </select>
                <select v-model="areaIdx" name="areas" class="tw-grow" required>
                  <option value="" disabled>請選擇鄉鎮市區</option>
                  <option v-for="(area, idx) in areas" :key="idx" :value="idx">
                    {{ area.name }}
                  </option>
                </select>
              </div>
              <input
                id="address"
                v-model="detailAddress"
                type="text"
                name="address"
                placeholder="請輸入街道、樓層等詳細地址"
                required
              />
              <input type="hidden" name="fullAddress" :value="address" />
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-2">
            <label>
              <span class="tw-font-regular tw-text-base tw-text-gray-800"
                >統編</span
              >
            </label>
            <input
              id="tax"
              type="text"
              name="tax"
              size="8"
              maxlength="8"
              pattern=".{7,8}[0-9]"
              placeholder="請輸入統編（非必填選項）"
            />
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2 sm:tw-w-6/12 tw-grow">
          <label>
            <span class="tw-font-regular tw-text-base tw-text-gray-800"
              >備註</span
            >
          </label>
          <textarea
            id="comment"
            class="tw-grow"
            name="comment"
            placeholder="請輸入備註"
          />
        </div>
        <input type="hidden" name="productData" :value="productData" />
      </div>
    </div>
    <div class="tw-flex tw-justify-center tw-gap-6 tw-text-center">
      <a
        href="/quote"
        style="border: 1px solid #6998ab; border-radius: 4px"
        class="
          v-btn v-btn--has-bg
          theme--light
          v-size--default
          tw-bg-white tw-text-primary
        "
        >上一步</a
      >
      <input
        type="submit"
        class="
          v-btn v-btn--is-elevated v-btn--has-bg
          theme--light
          elevation-2
          v-size--default
          tw-bg-primary tw-text-white
        "
        value="送出清單"
      />
    </div>
  </form>
</template>
<script>
import address from "../../assets/address.json";

export default {
  props: {
    csrfToken: {
      type: String,
      required: true,
    },
    storeUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cityIdx: "",
      areaIdx: "",
      detailAddress: "",
      cartType: null,
    };
  },
  created() {
    this.cartType = new URLSearchParams(window.location.search).get("type");
  },
  computed: {
    cities() {
      return address.cities;
    },
    areas() {
      if (this.cityIdx === "") {
        return [];
      }
      return this.cities[this.cityIdx].areas;
    },
    address() {
      if (this.cityIdx !== "" && this.areaIdx !== "") {
        return `${this.cities[this.cityIdx].name}${
          this.cities[this.cityIdx].areas[this.areaIdx].name
        }${this.detailAddress}`;
      }
      return null;
    },
    productData() {
      return JSON.stringify({
        cart: this.cartType !== "wish" ? localStorage.getItem("cart") : "[]",
        wish: localStorage.getItem("wish") || "[]",
      });
    },
  },
  methods: {
    initArea() {
      this.areaIdx = "";
    },
  },
};
</script>
