var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quotation-table"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","item-key":"index","item-class":_vm.rowClass,"mobile-breakpoint":"768"},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-category"},[_c('span',{class:item.categoryClass},[_vm._v(_vm._s(item.category))])])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-product"},[_c('v-select',{class:item.addRowCLass,attrs:{"items":_vm.getProducts(item.category),"append-outer-icon":"mdi-plus-circle","hide-details":"auto","label":"請選擇商品","solo":"","dense":""},on:{"change":function($event){return _vm.changeProduct(item)},"click:append-outer":function($event){return _vm.addRow(item)}},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}})],1)]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-count"},[(item.id != null)?_c('v-text-field',{attrs:{"append-outer-icon":"mdi-plus","prepend-icon":"mdi-minus","hide-details":"","solo":"","dense":""},on:{"click:append-outer":function($event){return _vm.changeCount(item, 1)},"click:prepend":function($event){return _vm.changeCount(item, -1)}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", _vm._n($$v))},expression:"item.count"}}):_vm._e()],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-price"},[_c('span',{staticClass:"text-original-price"},[_vm._v(_vm._s(_vm.moneyFormat(item.originalPrice)))]),_vm._v(" "),_c('span',{staticClass:"text-price"},[_vm._v(_vm._s(_vm.moneyFormat(item.price)))])])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-total"},[_c('span',[_vm._v(_vm._s(_vm.moneyFormat(item.total)))]),_vm._v(" "),_c('v-btn',{class:item.removeRowClass,attrs:{"icon":""},on:{"click":function($event){return _vm.removeRow(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"total-price"},[_c('span',[_vm._v("商品小計")]),_vm._v(" "),_c('span',{staticClass:"money"},[_vm._v(_vm._s(_vm.moneyFormat(_vm.totalPrice)))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }